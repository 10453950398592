<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t("message.telegram_managers") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <crm-input
                                        :size="'small'"
                                        :className="'w100'"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        :icon="'el-icon-search'"
                                    ></crm-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                            :v_can_create="'TelegramManagerController@store'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    class="table-my-code table-bordered"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>
                            <th v-if="columns.branch_id.show">
                                {{ columns.branch_id.title }}
                            </th>
                            <th v-if="columns.count.show">
                                {{ columns.count.title }}
                            </th>
                            <th v-if="columns.type.show">
                                {{ columns.type.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.branch_id.show">
                                <select-branch
                                    :placeholder="columns.branch_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.branch_id"
                                ></select-branch>
                            </th>
                            <th v-if="columns.count.show">
                                
                            </th>
                            <th v-if="columns.type.show">
                              <el-select
                              :size="'small'"
                              :class="mode ? 'filter__day' : 'filter__night'"
                            
                              :placeholder="$t('message.type')"
                              :multiple="true"
                              filterable
                              clearable
                              class="d-block"
                              v-model="filterForm.types"
                                 >
                                 <el-option
                                :label="$t('message.advance_salaries')"
                                :value="'advance_salaries'"
                              ></el-option>
                              <el-option
                                :label="$t('message.complaints')"
                                :value="'complaints'"
                              ></el-option>
                              <el-option
                                :label="$t('message.take_assents')"
                                :value="'take_assents'"
                              ></el-option>
                              <el-option
                                :label="$t('message.sick_leave')"
                                :value="'sick_leave'"
                              ></el-option>
                              <el-option
                                :label="$t('message.additional_works')"
                                :value="'additional_works'"
                              ></el-option>
                               <el-option
                                :label="$t('message.vacation')"
                                :value="'vacation'"
                              ></el-option>
                              <el-option
                                :label="$t('message.applications')"
                                :value="'applications'"
                              ></el-option>
                              <el-option
                                :label="$t('message.explanatory_letters')"
                                :value="'explanatory_letters'"
                              ></el-option>
                              <el-option
                                :label="$t('message.active_work')"
                                :value="'active_work'"
                              ></el-option>
                              <el-option
                              :label="$t('message.online_works')"
                              :value="'online_works'"
                            ></el-option>
                            </el-select>
                            </th>
                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                ></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                ></crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                              v-for="telegramManager in list"
                              :key="telegramManager.id"
                              class="cursor-pointer"
                                >
                              <td v-if="columns.id.show">{{ telegramManager.id }}</td>
                              <td v-if="columns.branch_id.show">
                                {{ telegramManager.branch.name }}
                              </td>
                              <td v-if="columns.count.show"  @click="showStaffList(telegramManager.id)">
                                {{ telegramManager.count  }}
                              </td>
                              <td v-if="columns.type.show">
                                  <el-tag type="info"   effect="dark" style="margin-right:5px; margin-bottom: 5px;" v-for="type in telegramManager.types" :key="type.id">{{$t('message.'+type.type)}}</el-tag>
                              </td>

                            <td v-if="columns.created_at.show">
                                {{ telegramManager.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ telegramManager.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="telegramManager"
                                    :actions="actions"
                                    @edit="edit"
                                    @delete="destroy"
                                    :permissionShow="'TelegramManagerController@update'"
                                    :permissionDestroy="'TelegramManagerController@destroy'"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <div class="app-modal app-modal__full">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    size="70%"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    ></crm-create>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
         <el-drawer
            title="I'm outer Drawer"
            :visible.sync="showUseerModal"
            size="30%"
            :with-header="false"
            ref="show-users"
            @opened="drawerOpened('show-users')"
          >
            <div>
              <show-users
                :staff="selected"
                @closeUserModal="closeUserModal"
                :child="child"
                ref="show-users"
              ></show-users>
            </div>
          </el-drawer>
    </div>
</template>
<script>
import list from "@/utils/mixins/list";
import showUsers from "./components/show-users.vue";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update.vue";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "TelegramManagerController",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        showUsers
    },

    data() {
        return {
              child: {},
              showUseerModal: false,
              selected: null,
        };
    },
    computed: {
        ...mapGetters({
            list: "telegramManager/list",
            columns: "telegramManager/columns",
            pagination: "telegramManager/pagination",
            statues: "telegramManager/statues",
            filter: "telegramManager/filter",
            sort: "telegramManager/sort",
            mode:'MODE'
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "telegramManager/index",
            setPagination: "telegramManager/setPagination",
            updateSort: "telegramManager/updateSort",
            updateFilter: "telegramManager/updateFilter",
            updateColumn: "telegramManager/updateColumn",
            updatePagination: "telegramManager/updatePagination",
            show: "telegramManager/show",
            empty: "telegramManager/empty",
            delete: "telegramManager/destroy",
        }),

         showStaffList(telegramManager_id) {
            this.showUseerModal = true;
            this.child.id = telegramManager_id;
          },
          closeUserModal(val) {
            this.showUseerModal = val;
          },
          drawerOpened(ref) {
            if (this.$refs[ref]) {
              if (_.isFunction(this.$refs[ref].opened)) {
                this.$refs[ref].opened(this.child);
              }
            }
    },
    },
};
</script>
